<template>
  <v-card elevation="0">
    <v-card-title>{{ $t("ui_plugin_configs") }}</v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(th, i) in FIELDS_DISPLAY" :key="i" class="text-left">
                {{ th }}
              </th>
              <th>{{ $t("ui_actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in rows" :key="row.id">
              <td v-for="(td, i) in FIELDS_DISPLAY" :key="i">
                {{ row[td] }}
              </td>
              <td style="min-width: 120px">
                <v-btn icon color="green" x-small @click="openEditModal(row)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon color="pink" x-small @click="deleteRow(row.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-actions>
      <v-btn color="accent" elevation="1" small @click="openAddModal">{{
        $t("ui_add")
      }}</v-btn>
    </v-card-actions>

    <AdminModalForm
      ref="AdminModalEditForm"
      :fieldsEdit="FIELDS_EDIT"
      rows="16"
      @onSave="onEdit"
    />
    <AdminModalForm
      ref="AdminModalCreateForm"
      :fieldsEdit="FIELDS_CREATE"
      rows="16"
      @onSave="onSave"
    />
  </v-card>
</template>

<script>
import api from "@/api";
import AdminModalForm from "@/apps/admin/components/AdminModalForm/AdminModalForm.vue";

import { mapState } from "vuex";

const FIELDS_DISPLAY = ["id", "version", "plugin_id"];
const FIELDS_EDIT = ["config", "version"];
const FIELDS_CREATE = ["config", "version", "pluginId"];

export default {
  components: { AdminModalForm },
  data() {
    return {
      FIELDS_DISPLAY,
      FIELDS_EDIT,
      FIELDS_CREATE,
      rows: [],
    };
  },
  computed: {
    ...mapState("core$common", ["node"]),
  },
  methods: {
    deleteRow(id) {
      api
        .request({
          name: "deletePluginConfig",
          pathParam: { nodeId: this.node.id, pluginConfigId: id },
          showAlert: true,
        })
        .then(() => this.getPlugins());
    },
    onEdit(data) {
      api
        .request({
          name: "editPluginConfig",
          pathParam: { nodeId: this.node.id, pluginConfigId: data.id },
          payload: data,
          showAlert: true,
        })
        .then(() => this.getPlugins());
    },
    onSave(data) {
      api
        .request({
          name: "createPluginConfig",
          pathParam: { nodeId: this.node.id, pluginId: data.pluginId },
          payload: data,
          showAlert: true,
        })
        .then(() => this.getPlugins());
    },
    openAddModal() {
      this.$refs.AdminModalCreateForm.open({});
    },
    openEditModal(data) {
      this.$refs.AdminModalEditForm.open(data);
    },
    getPlugins() {
      api
        .request({
          name: "getPluginConfigs",
          pathParam: this.node.id,
        })
        .then(({ data }) => (this.rows = data.data));
    },
  },
  created() {
    this.getPlugins();
  },
};
</script>

<style lang="scss"></style>
